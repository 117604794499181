<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Formations
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiSchool }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text class="d-flex justify-space-between">
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-switch
          v-model="showArchived"
          label="Afficher les formations archivées"
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3 mt-4 ml-10"
          right
          top
          @click="$router.push({name:'formation-create'})"
        >
          Nouvelle Formation
        </v-btn>
      </v-card-text>
      <v-divider>
      </v-divider>
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="table"
      ></v-skeleton-loader>
      <v-card-text v-else>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="formationsToShow"
          class="elevation-1"
        >
          <!-- STATUS -->
          <template
            #[`item.status`]="{item}"
          >
            <item-status-chip
              :status="item.status"
            ></item-status-chip>
          </template>
          <!-- END STATUS -->
          <!-- DUREE -->
          <template
            #[`item.duree`]="{item}"
          >
            <div
              v-if="item.duree"
              class="d-flex flex-column text-center"
            >
              {{ item.duree }}h
            </div>
          </template>
          <!-- END DUREE -->
          <!-- VISIO -->
          <template
            #[`item.visio`]="{item}"
          >
            <div
              v-if="item.visio"
              class="d-flex flex-column text-center"
            >
              {{ item.visio }}h
            </div>
            <div
              v-else
              class="d-flex flex-column text-center"
            >
              Pas de visio paramétré
            </div>
          </template>
          <!-- END DUREE -->
          <!-- NB ELEVE -->
          <template
            #[`item.students`]="{item}"
          >
            <div
              v-if="item.students"
              class="d-flex flex-column text-center"
            >
              {{ item.students.length }}
            </div>
          </template>
          <!-- END NB ELEVES -->
          <!-- Prix -->
          <template
            #[`item.prix`]="{item}"
          >
            <div
              v-if="item.prix"
              class="d-flex flex-column text-center"
            >
              {{ item.prix.toLocaleString('fr-FR') }} €
            </div>
          </template>
          <!-- END PRIX -->

          <!-- CERTIFS -->
          <template #[`item.certification`]="{item}">
            <div
              v-if="item.certification"
              class="flex-column text-center"
            >
              <v-chip
                class="font-weight-medium text-center px-4 "
                color="primary"
                label
                small
              >
                <v-icon left>
                  {{ icons.mdiLabel }}
                </v-icon>
                {{ item.certification ? certifName(item.certification) : 'Pas de certification' }}
              </v-chip>
            </div>
          </template>
          <!-- END CERTIFS -->

          <!-- LIEN EDITION -->
          <template #[`item.change`]="{item}">
            <div class="d-flex justify-space-around">
              <v-btn
                icon
                @click="$router.push({ name: 'formation-edit', params: { id: item.id }})"
              >
                <v-icon>
                  {{ icons.mdiSquareEditOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click="archive(item.id)"
              >
                <v-icon>
                  {{ icons.mdiArchive }}
                </v-icon>
              </v-btn>
            </div>
          </template>
          <!-- END LIEN EDITION -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiLabel,
  mdiMagnify,
  mdiSchool,
  mdiArchive,
} from '@mdi/js'
import ItemStatusChip from '@/components/ItemStatusChip.vue'

export default {
  components: {
    ItemStatusChip,
  },
  data() {
    return {
      showArchived: false,
      loading: true,
      search: '',
      formations: [],
      certifications: [],
      formationsToShow: [],
      headers: [
        {
          text: 'Nom de la formation',
          align: 'start',
          sortable: true,
          value: 'nom',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status',
        },
        { text: 'Durée (en heures)', value: 'duree' },
        { text: 'Visio (en heures)', value: 'visio' },
        { text: 'Nombre d\'élèves', value: 'students' },
        { text: 'Coût', value: 'prix' },
        { text: 'Certification', value: 'certification' },
        { text: 'Modification', value: 'change' },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiLabel,
        mdiMagnify,
        mdiSchool,
        mdiArchive,
      },
    }
  },
  watch: {
    showArchived() {
      this.filterFormations()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchFormations(), this.fetchCertifs()])
        .then(values => {
          [this.formations, this.certifications] = values
          this.filterFormations()
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchFormations() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/formations`)

      return response.data
    },
    async fetchCertifs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/certifications`)

      return response.data
    },
    certifName(id) {
      const certif = this.certifications.find(x => x.id === id)
      if (typeof certif !== 'undefined') {
        return certif.nom.slice(0, 20)
      }

      return 'Pas de certification'
    },
    archive(id) {
      this.$http.post(`${process.env.VUE_APP_API_URL}/formations/archive`, { id })
        .then(res => {
          if (res.status === 200) {
            const formations = [...this.formations]
            const foundIndex = this.formations.findIndex(x => x.id === res.data.id)
            formations[foundIndex] = res.data
            this.formations = formations
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Formation archivée',
              value: true,
            })
            this.filterFormations()
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
    filterFormations() {
      const formations = [...this.formations]
      if (this.showArchived) {
        this.formationsToShow = formations
      } else {
        const filteredFormations = formations.filter(x => x.status !== 'archived')

        this.formationsToShow = filteredFormations
      }
    },

  },
}
</script>

<style scoped>
    th {
        text-align: center;
    }
</style>
