var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v(" Formations ")]),_c('v-icon',{attrs:{"top":"","right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiSchool)+" ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex justify-space-between"},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Rechercher","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-switch',{attrs:{"label":"Afficher les formations archivées"},model:{value:(_vm.showArchived),callback:function ($$v) {_vm.showArchived=$$v},expression:"showArchived"}}),_c('v-spacer'),_c('v-btn',{staticClass:"me-3 mt-4 ml-10",attrs:{"color":"primary","right":"","top":""},on:{"click":function($event){return _vm.$router.push({name:'formation-create'})}}},[_vm._v(" Nouvelle Formation ")])],1),_c('v-divider'),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.formationsToShow},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('item-status-chip',{attrs:{"status":item.status}})]}},{key:"item.duree",fn:function(ref){
var item = ref.item;
return [(item.duree)?_c('div',{staticClass:"d-flex flex-column text-center"},[_vm._v(" "+_vm._s(item.duree)+"h ")]):_vm._e()]}},{key:"item.visio",fn:function(ref){
var item = ref.item;
return [(item.visio)?_c('div',{staticClass:"d-flex flex-column text-center"},[_vm._v(" "+_vm._s(item.visio)+"h ")]):_c('div',{staticClass:"d-flex flex-column text-center"},[_vm._v(" Pas de visio paramétré ")])]}},{key:"item.students",fn:function(ref){
var item = ref.item;
return [(item.students)?_c('div',{staticClass:"d-flex flex-column text-center"},[_vm._v(" "+_vm._s(item.students.length)+" ")]):_vm._e()]}},{key:"item.prix",fn:function(ref){
var item = ref.item;
return [(item.prix)?_c('div',{staticClass:"d-flex flex-column text-center"},[_vm._v(" "+_vm._s(item.prix.toLocaleString('fr-FR'))+" € ")]):_vm._e()]}},{key:"item.certification",fn:function(ref){
var item = ref.item;
return [(item.certification)?_c('div',{staticClass:"flex-column text-center"},[_c('v-chip',{staticClass:"font-weight-medium text-center px-4 ",attrs:{"color":"primary","label":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiLabel)+" ")]),_vm._v(" "+_vm._s(item.certification ? _vm.certifName(item.certification) : 'Pas de certification')+" ")],1)],1):_vm._e()]}},{key:"item.change",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'formation-edit', params: { id: item.id }})}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.archive(item.id)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiArchive)+" ")])],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }